var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({ handler: _vm.onIntersect }),expression:"{ handler: onIntersect }"}]},[_c('ManagementLayout',{attrs:{"show":!_vm.isFetchingKV},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("KV Store")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" Manage your team's key/value store ")]},proxy:true},(_vm.hasPermission('create', 'key-value') && _vm.maxKVCount)?{key:"cta",fn:function(){return [_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","large":"","disabled":_vm.kv ? _vm.kv.length >= _vm.maxKVCount : false},on:{"click":function($event){_vm.expanded = []
          _vm.previousKVName = null
          _vm.keyInput = null
          _vm.resetKvValueInput()
          _vm.isKvUpdate = false
          _vm.keyModifyDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" add ")]),_vm._v(" Add KV ")],1)]},proxy:true}:null,{key:"alerts",fn:function(){return [(!_vm.hasPermission('create', 'key-value'))?_c('v-alert',{staticClass:"mx-auto",attrs:{"border":"left","colored-border":"","elevation":"2","type":"warning","tile":"","icon":"lock","max-width":"380"}},[_vm._v(" You don't have permission to manage kv. ")]):_vm._e(),(!_vm.maxKVCount)?_c('v-alert',{staticClass:"mx-auto",attrs:{"border":"left","colored-border":"","elevation":"2","type":"warning","tile":"","icon":"lock","max-width":"380"}},[_vm._v(" Your team's license does not include this feature. Please contact help@prefect.io for more information. ")]):_vm._e()]},proxy:true}],null,true)},[(
        !_vm.$vuetify.breakpoint.mdAndUp && !_vm.hasPermission('create', 'key-value')
      )?_c('v-text-field',{staticClass:"rounded-0 elevation-1 mb-1",attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search for a key","prepend-inner-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),(_vm.maxKVCount)?_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"py-1 mr-2 d-flex justify-end"},[_c('v-text-field',{staticClass:"rounded-0 elevation-1",style:({
            'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '360px' : null
          }),attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search for a key","prepend-inner-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.kvHeaders,"items":_vm.kv,"header-props":{ 'sort-icon': 'arrow_drop_up' },"sort-by":"created","sort-desc":"","search":_vm.search,"loading":_vm.$apollo.queries.kv.loading,"expanded":_vm.expanded,"show-expand":"","single-expand":true,"no-results-text":"No keys found. Try expanding your search?","no-data-text":"Your team does not have any keys yet."},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.handleKVExpand},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('resettable-wrapper',{key:item.id,staticClass:"resettable-dictionary-json",on:{"input":_vm.setSelectedInputMode},model:{value:(_vm.KvValueInput),callback:function ($$v) {_vm.KvValueInput=$$v},expression:"KvValueInput"}},[_c('code-input',{ref:"KvValueInput",staticClass:"text-body-1 mt-2",attrs:{"mode":_vm.selectedInputMode,"editors":['text', 'json']},on:{"update:mode":function($event){_vm.selectedInputMode=$event}},model:{value:(_vm.KvValueInput),callback:function ($$v) {_vm.KvValueInput=$$v},expression:"KvValueInput"}}),_c('div',{staticClass:"d-flex flex-grow-1 justify-end mb-5"},[_c('v-btn',{attrs:{"small":"","color":"primary","loading":_vm.isSettingKV},on:{"click":_vm.validateAndSetKV}},[_vm._v("Save")])],1)],1)],1)]}},{key:"item.value",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-truncate",staticStyle:{"width":"15vw"}},[_vm._v(" "+_vm._s(item.value)+" ")])])]}},{key:"item.created",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.created))+" ")]}},{key:"item.updated",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.updated))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(_vm.hasPermission('update', 'key-value'))?_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"primary","disabled":_vm.getItemIsExpanded(item)},on:{"click":function($event){_vm.isEditable = !_vm.isEditable
                  _vm.openKVEdit(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}],null,true)},[_vm._v(" Modify key ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.hasPermission('delete', 'key-value'))?_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.openKVDeleteDialog(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}],null,true)},[_vm._v(" Delete key ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.copyValue(item)}}},on),[_c('v-icon',[_vm._v("content_copy")])],1)]}}],null,true)},[_vm._v(" Copy value ")])]}},{key:"footer.page-text",fn:function(){return [_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.kv ? _vm.kv.length : 0)+" out of "+_vm._s(_vm.maxKVCount)+" keys used ")])]},proxy:true}],null,false,3824823620)})],1)],1):_vm._e(),_c('ConfirmDialog',{attrs:{"dialog-props":{ 'max-width': '75vh' },"loading":_vm.isSettingKV,"confirm-text":"Save","title":"Create KV"},on:{"confirm":_vm.validateAndSetKV,"cancel":_vm.resetSelectedKV},model:{value:(_vm.keyModifyDialog),callback:function ($$v) {_vm.keyModifyDialog=$$v},expression:"keyModifyDialog"}},[_c('v-text-field',{staticClass:"_lr-hide mt-6",attrs:{"rules":[_vm.rules.required],"messages":_vm.kvExists
          ? 'A key with this this name already exists. Clicking CONFIRM will overwrite it.'
          : null,"autofocus":"","data-private":"","single-line":"","outlined":"","dense":"","placeholder":"Key","prepend-inner-icon":"vpn_key","validate-on-blur":""},model:{value:(_vm.keyInput),callback:function ($$v) {_vm.keyInput=$$v},expression:"keyInput"}}),_c('code-input',{ref:"KvValueInput",staticClass:"text-body-1",attrs:{"placeholder":"Value","mode":_vm.selectedInputMode,"editors":['text', 'json']},on:{"update:mode":function($event){_vm.selectedInputMode=$event}},model:{value:(_vm.KvValueInput),callback:function ($$v) {_vm.KvValueInput=$$v},expression:"KvValueInput"}})],1),_c('ConfirmDialog',{attrs:{"type":"error","loading":_vm.isDeletingKV,"confirm-text":"Delete","dialog-props":{ 'max-width': '500' },"title":"Are you sure you want to delete this key?"},on:{"confirm":function($event){return _vm.deleteKV(_vm.selectedKV)}},model:{value:(_vm.keyDeleteDialog),callback:function ($$v) {_vm.keyDeleteDialog=$$v},expression:"keyDeleteDialog"}},[_vm._v(" This action cannot be undone. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }